import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css'

const container = document.getElementById('root_mail_sign_up_form');
const root = createRoot(container);

const RootApp = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Wait until the page is fully loaded before rendering React
    window.addEventListener('load', () => {
      setIsReady(true);
    });
  }, []);

  if (!isReady) {
    // Return null to keep displaying the static content until the React app is ready
    return null;
  }

  return (
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
};

// Render the React app only after window is fully loaded
root.render(<RootApp />);