// ./src/App.jsx
import React from 'react';
import {Helmet} from 'react-helmet';
import SignUpForm from './components/SignUpForm.jsx';
import ExtendedSignUpFormFacebook from "./components/ExtendedSignUpFormFacebook.jsx";
import ExtendedSignUpFormGoogle from "./components/ExtendedSignUpFormGoogle.jsx";

const App = () => {
    return (<div className="app" style={{minHeight: '100vh', backgroundColor: '#2c2c2c', color: '#fff', padding: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Helmet>
            <title>Sign Up for Exclusive Updates - Pattern Library</title>
            <meta name="description" content="Sign up to receive exclusive updates on new patterns, products, and offers. Join our mailing list and get access to our online pattern library."/>
            <meta name="keywords" content="sign up, mailing list, exclusive offers, pattern library, new products"/>
            <meta name="description" content="Join our Medieval Crafting Community to stay updated on new patterns, products, and exclusive offers. Get access to our online pattern library!"/>
            <meta property="og:title" content="Join Our Medieval Crafting Community!"/>
            <meta property="og:description" content="Sign up to receive free patterns, updates, and special offers. Get access to our online pattern library!"/>
            <meta property="og:image" content="https://stevenlawton.com/wp-content/uploads/2024/11/13thc-1.jpg"/>
            <meta property="og:url" content="https://stevenlawton.com/"/>
            <meta property="og:type" content="website"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="Join Our Medieval Crafting Community!"/>
            <meta name="twitter:description" content="Sign up to receive free patterns, updates, and special offers. Get access to our online pattern library!"/>
            <meta name="twitter:image" content="https://stevenlawton.com/wp-content/uploads/2024/11/13thc-1.jpg"/>
        </Helmet>

        <main style={{width: '100%', maxWidth: '600px'}}>
            <div>
                <SignUpForm/>
            </div>
        </main>
    </div>);
};

export default App;
